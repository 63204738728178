
import { defineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";

//组件
import moment from "moment";
import Header from "@/components/Header.vue";
import SystemUtils from "@/utils/SystemUtils";
import Utils from "@/utils/Utils";
import { MeetingBookService } from "@/services/meeting/MeetingBookService";

export default defineComponent({
  name: "MeetingRoomTimeBook",
  components: {
    Header,
  },
  data() {
    const router = useRouter();
    const meetingService = new MeetingBookService();
    return {
      router: router,
      useRoute: useRoute(),

      btnIconRight: ["icontianjiaweiyiti"],
      btnSizeRight: ["34px"],
      //x轴
      days: 5,
      dateDays: [
        {
          day: "",
          year: "",
          yudinglist: [
            {
              yiyuding: false,
              rows: 1,
              title: "",
              userName: "",
              start: "",
              end: "",
              style: {
                color: "",
                "background-color": "",
                "border-color": "",
                height: "",
              },
              status: 0,
              statusName: "",
            },
          ],
        },
      ],
      dateYears: new Array<string>(),
      yearSize: new Array<number>(),
      yearsSplit: 5,
      //y轴
      times: 25,
      dateTimes: new Array<string>(),

      id: "",
      roomName: "",
      startDate: "",
      endDate: "",

      //选择的日期
      selectedDay: 0,

      //选择时间区间
      selectedStartTime: -1,
      selectedEndTime: -1,

      initHour: 8,
      currentIndex: 0,

      gmRole: true,

      radioStartTime: new Array<boolean>(),
      jumpSelect: "true",
      //选择日期
      // selectDateEL: new Object(),
      selectDate: "",
      minYear: "",
      selectDateTemp: "",

      meetingService: meetingService,
    };
  },
  mounted() {
    this.id = this.useRoute.query.roomId as string;
    this.roomName = this.useRoute.query.roomName as string;
    this.initGridHeight();

    // let strJson = localStorage.getItem("antiepidemic_account") as string;

    // let userInfo = JSON.parse(strJson);
    // // console.log("12121212121"+userInfo);

    // let roles = userInfo["user"] ? userInfo["user"].orgRoles : [];
    // for (let index = 0; index < roles.length; index++) {
    //   const element = roles[index];
    //   if (element.code == "attendance_GM") {
    //     console.log("总经理角色");
    //     this.gmRole = true;
    //     break;
    //   }
    // }

    this.initDateDays("init");
  },
  created() {
    // this.selectDateEL = s1;
    let thisDate = new Date();
    let thisDateStamp = thisDate.setDate(thisDate.getDate() + this.days);
    thisDate = new Date(thisDateStamp);
    this.minYear =
      thisDate.getFullYear() +
      "-" +
      (thisDate.getMonth() + 1 < 10
        ? "0" + (thisDate.getMonth() + 1)
        : thisDate.getMonth() + 1) +
      "-" +
      (thisDate.getDate() < 10 ? "0" + thisDate.getDate() : thisDate.getDate());
    // this.activatedRoute.queryParams.subscribe((params) => {
    //   this.id = params.roomId;
    //   this.roomName = params.roomName;
    // });
  },
  methods: {
    ionViewDidEnter() {
      this.radioStartTime = [];
      this.initScrollToday();
      this.getList();
    },

    initScrollToday(): void {
      let listDatadocument = document.getElementById("listData") as HTMLElement;
      if (
        this.selectedDay === 0 &&
        this.dateDays[0].year + "." + this.dateDays[0].day != this.selectDate
      ) {
        this.currentIndex = this.getCurrentIndex();
        let row = document.getElementById(
          "row" + this.currentIndex
        ) as HTMLElement;

        let ss: any = document.getElementById("row" + (this.currentIndex - 5));

        let rowdocument: HTMLElement = ss;

        // let rowdocument = rowdoc;
        if (this.currentIndex > 7) {
          listDatadocument.scrollTop = rowdocument.getBoundingClientRect().top;
        } else {
          listDatadocument.scrollTop = 0;
        }
      } else {
        listDatadocument.scrollTop = 0;
      }
    },

    initGridHeight(): void {
      let htmlHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      let grid = document.getElementById("listData") as HTMLDivElement;
      let headerHeight = document.getElementsByTagName("ion-header")[0]
        .offsetHeight;
      // let s: any = document.getElementById("gridDate");
      // let DateHeight: HTMLElement = s;
      let gridDateHeight = (document.getElementById("gridDate") as HTMLElement)
        .offsetHeight;
      grid.style.height =
        (htmlHeight - headerHeight - gridDateHeight) / 37.5 + "rem";
    },
    initDateTimes(): void {
      this.dateTimes = [];
      for (let index = 0; index < this.times; index++) {
        this.dateTimes.push(this.getDateTime(index));
        this.radioStartTime[index] = false;
      }
    },
    initDateList(): void {
      this.dateDays = [];
      for (let index = 0; index < this.days; index++) {
        let day = {
          day: this.getDateDay(index),
          year: this.getDateDay(index).substring(0, 4),
          yudinglist: [],
        };
        this.dateDays.push(day);
      }
    },
    initSelectDateList(): void {
      this.dateDays = [];
      let day = {
        day: this.selectDate,
        year: this.selectDate.substring(0, 4),
        yudinglist: [],
      };
      this.dateDays.push(day);
    },
    /**
     * param type:init默认显示日期列表  select：选择日期后显示 */
    initDateDays(type: string) {
      if (type == "init") {
        this.initDateList();
      } else if (type == "select") {
        this.initSelectDateList();
      }

      for (let index = 0; index < this.dateDays.length; index++) {
        let year: string = this.dateDays[index].day.substring(0, 4);
        if (index == 0) {
          this.dateYears.push(year);
          this.yearsSplit = 1;
          this.yearSize.push(this.days * 2);
        } else if (year != this.dateYears[0]) {
          if (this.dateYears.length === 1) {
            this.dateYears.push(year);
            this.yearSize.push((this.days - index) * 2);
          }
        } else {
          this.yearsSplit = index + 1;
          this.yearSize[0] = (index + 1) * 2;
        }
        this.dateDays[index].day = this.dateDays[index].day.substring(5);
      }

      this.initDateTimes();

      if (this.selectDate != "") {
        this.initScrollToday();
      }
    },

    getCurrentIndex(): number {
      let date1 = new Date();
      let h = (date1.getHours() - this.initHour) * 2;
      if (h < 0) {
        return -1;
      }
      let m = date1.getMinutes() - 30;
      if (m < 0) {
        h += 1;
      } else {
        h += 2;
      }
      return h;
    },

    getDateTime(t: number): string {
      let date1 = new Date();
      date1.setHours(this.initHour + Math.floor(t / 2));
      date1.setMinutes(t % 2 == 0 ? 0 : 30);
      return date1.getHours() + ":" + (date1.getMinutes() == 0 ? "00" : "30");
    },

    getDateDay(d: number): string {
      let date1 = new Date();
      let date2 = new Date(date1);
      date2.setDate(date1.getDate() + d);
      return (
        date2.getFullYear() +
        "." +
        (date2.getMonth() + 1) +
        "." +
        date2.getDate()
      );
    },

    getYudingObj(): any {
      return {
        yiyuding: false,
        rows: 1,
        title: "",
        userName: "",
        start: "",
        end: "",
        style: "",
        status: 0,
        statusName: "",
      };
    },

    setYudingObj(yuding: any, rows: number): any {
      return {
        yiyuding: yuding.yiyuding,
        rows: rows,
        title: yuding.title,
        userName: yuding.userName,
        start: yuding.start,
        end: yuding.end,
        style: yuding.style,
        status: yuding.status,
        statusName: yuding.statusName,
      };
    },

    getList(): void {
      let that = this;
      this.meetingService
        .getRoomBookDetail(
          this.id,
          this.dateDays[0].year + "-" + this.dateDays[0].day.replace(".", "-"),
          this.dateDays[this.dateDays.length - 1].year +
            "-" +
            this.dateDays[this.dateDays.length - 1].day.replace(".", "-")
        )
        .then((result: any) => {
          this.dateDays[this.selectedDay].yudinglist = [];

          let initYuding = that.getYudingObj();
          initYuding.yiyuding = false;
          initYuding.rows = 1;
          if (
            this.selectedDay === 0 &&
            this.dateDays[0].year + "." + this.dateDays[0].day !=
              this.selectDate
          ) {
            initYuding.style = {
              "border-bottom": "1px solid #eaeaea",
            };
          }
          this.dateDays[this.selectedDay].yudinglist[0] = initYuding;
          for (let j = 1; j < this.times; j++) {
            let iy = that.getYudingObj();
            iy.yiyuding = false;
            iy.rows = 1;
            if (
              this.selectedDay === 0 &&
              this.currentIndex > j &&
              this.dateDays[0].year + "." + this.dateDays[0].day !=
                this.selectDate
            ) {
              iy.style = {
                "border-bottom": "1px solid #eaeaea",
              };
            }
            this.dateDays[this.selectedDay].yudinglist[j] = iy;
          }
          // console.log("dateDays:"+this.dateDays[0].yudinglist);
          if (result) {
            for (let i = 0; i < result.length; i++) {
              let yuding = that.getYudingObj();
              yuding.yiyuding = true;
              yuding.title = result[i].title ? result[i].title : "";
              yuding.userName = result[i].userName ? result[i].userName : "";

              let start = new Date(result[i].start.replace(/-/g, "/"));
              let tabDay = start.getMonth() + 1 + "." + start.getDate();
              yuding.start =
                start.getHours() +
                ":" +
                (start.getMinutes() == 0 ? "00" : "30");
              let end = new Date(result[i].end.replace(/-/g, "/"));
              yuding.end =
                end.getHours() + ":" + (end.getMinutes() == 0 ? "00" : "30");

              yuding.status = result[i].status;
              yuding.statusName = result[i].statusName;

              for (let index = 0; index < that.days; index++) {
                if (that.dateDays[index].day == tabDay) {
                  let f: boolean = false;
                  let lastRows: number = 0;
                  for (let j = 0; j < that.times; j++) {
                    if (that.dateTimes[j] == yuding.start) {
                      let r = that.dateTimes[j + 1] == yuding.end ? 1 : 0;
                      let nyuding = that.setYudingObj(yuding, r);
                      that.dateDays[index].yudinglist[j + 1] = nyuding;
                      that.dateDays[index].yudinglist[j + 1].style = {
                        color: result[i].textColor,
                        "background-color": result[i].backgroundColor,
                        "border-color": result[i].borderColor,
                        height: "",
                      };
                      f = true;
                      lastRows = j + 1;
                    } else if (f) {
                      if (that.dateTimes[j] != yuding.end) {
                        let nyuding = that.setYudingObj(yuding, 0);
                        that.dateDays[index].yudinglist[j + 1] = nyuding;
                        that.dateDays[index].yudinglist[j + 1].style = {
                          color: result[i].textColor,
                          "background-color": result[i].backgroundColor,
                          "border-color": result[i].borderColor,
                          height: "",
                        };
                      } else {
                        let nyuding = that.setYudingObj(
                          yuding,
                          j + 1 - lastRows
                        );
                        that.dateDays[index].yudinglist[lastRows] = nyuding;
                        that.dateDays[index].yudinglist[lastRows].style = {
                          color: result[i].textColor,
                          "background-color": result[i].backgroundColor,
                          "border-color": result[i].borderColor,
                          height: "",
                        };
                        that.dateDays[index].yudinglist[j].style = {
                          color: result[i].textColor,
                          "background-color": result[i].backgroundColor,
                          "border-color": result[i].borderColor,
                          height: "90%",
                        };
                        j = that.times;
                        index = that.days;
                        f = false;
                        break;
                      }
                    }
                  }
                }
              }
            }
          }
        })
        .catch(() => {});
    },
    goHomeBack(): void {
      //   this.router.navigate(["meeting-list"]);
    },
    bindDetail(): void {
      if (this.selectedStartTime == -1 || this.selectedEndTime == -1) {
        Utils.presentToastWarning("请选择预订开始时间");
        return;
      }
      let startDate = "";
      if (this.selectedDay <= this.yearsSplit) {
        startDate = this.dateYears[0];
      } else {
        startDate = this.dateYears[1];
      }
      let d = this.dateDays[this.selectedDay];
      let dd = d.day.replace(".", ",").split(",");
      startDate =
        startDate + "-" + (parseInt(dd[0]) < 10 ? "0" + dd[0] : dd[0]);
      startDate =
        startDate + "-" + (parseInt(dd[1]) < 10 ? "0" + dd[1] : dd[1]);
      let startTime = this.dateTimes[this.selectedStartTime - 1];
      // this.router.navigate(["meeting-list/room-detail"], {
      //   queryParams: {
      //     id: this.id,
      //     startDate: startDate,
      //     startTime: startTime,
      //     selectedStartTime: this.selectedStartTime,
      //     selectedEndTime: this.selectedEndTime,
      //     jumpSelect: this.jumpSelect,
      //   },
      // });
      this.router.push({
        name: "RoomBookPage",
        params: {
          id: this.id,
          // roomName: this.roomName,
          startDate: startDate,
          startTime: startTime,
          selectedStartTime: this.selectedStartTime,
          selectedEndTime: this.selectedEndTime,
          jumpSelect: this.jumpSelect,
        },
      });
    },

    bindDayHandler(i: number): void {
      if (this.selectedDay != i) {
        this.selectedDay = i;
        this.selectedStartTime = -1;
        this.selectedEndTime = -1;
        this.resetSelect(-1);
        this.getList();
      } else {
        return;
      }
      this.initScrollToday();
    },

    resetSelect(i: any): void {
      for (let index = 0; index < this.times; index++) {
        this.radioStartTime[index] = false;
      }
      if (i >= 0) {
        this.radioStartTime[i] = true;
      }
    },

    bindDayTimes(i: number, bo: boolean): void {
      console.log(bo);
      if (bo) {
        this.resetSelect(i);
        this.selectedStartTime = i;
        let flag = true;
        let n: number = i;
        let cInfo = this.dateDays[this.selectedDay].yudinglist[n];
        if (cInfo.yiyuding) {
          let temList = this.dateDays[this.selectedDay].yudinglist;
          for (let index = i; index < temList.length; index++) {
            let element = temList[index];
            if (element.start != cInfo.start) {
              console.log("结束");
              break;
            } else {
              console.log("制空对象");
              this.dateDays[this.selectedDay].yudinglist[
                index
              ] = this.getYudingObj();
            }
          }
          for (let index = i - 1; index < temList.length; index--) {
            let element = temList[index];
            console.log(element);
            if (element.start != cInfo.start) {
              console.log("结束");
              break;
            } else {
              console.log("制空对象");
              this.dateDays[this.selectedDay].yudinglist[
                index
              ] = this.getYudingObj();
            }
          }
        }
        while (flag) {
          n += 1;
          if (n <= this.dateTimes.length - 1) {
            let yuding = this.dateDays[this.selectedDay].yudinglist[n];
            if (yuding && yuding.yiyuding) {
              this.selectedEndTime = n;
              flag = false;
            }
          } else {
            this.selectedEndTime = this.dateTimes.length - 1;
            flag = false;
          }
        }
      }
    },
    //选择日期
    selectDateHandler() {
      this.selectDateTemp = "";
      let s: any = document.querySelector("#selectDate");
      let s1: HTMLElement = s;
      let selectDateEL = s1;
      selectDateEL.click();
    },

    //日期选择
    dateChangeHandle(e: any): void {
      if (e.detail.value != "") {
        let date = e.detail.value.slice(0, 10);
        let selected = new Date(date);
        this.selectDate =
          selected.getFullYear() +
          "." +
          (selected.getMonth() + 1) +
          "." +
          selected.getDate();
        this.selectDateTemp = date;
        this.initDateDays("select");
        this.selectedDay = 0;
        this.selectedStartTime = -1;
        this.selectedEndTime = -1;
        this.resetSelect(-1);
        this.getList();
      }
    },
    //返回今日
    returnDateHandler(): void {
      this.initDateDays("init");
      this.selectedDay = 0;
      this.selectedStartTime = -1;
      this.selectedEndTime = -1;
      this.resetSelect(-1);
      this.getList();
    },
  },
});
